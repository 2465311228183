import { render, staticRenderFns } from "./notPass.vue?vue&type=template&id=661d3a83&scoped=true&"
import script from "./notPass.vue?vue&type=script&lang=js&"
export * from "./notPass.vue?vue&type=script&lang=js&"
import style0 from "./notPass.vue?vue&type=style&index=0&id=661d3a83&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661d3a83",
  null
  
)

export default component.exports