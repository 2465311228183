<template>
  <div class="merch-box">
    <div class="title">审核结果</div>
    <div class="center-box">
      <div class="passImg" v-if="recorItem.step == 5">
        <img src="@/assets/img/audit/refuse.png" alt="" />
        未通过
      </div>
      <div class="passImg" v-if="recorItem.step == 4">
        <img src="@/assets/img/audit/pass.png" alt="" />
        已通过
      </div>
      <div class="verifier-per">
        <div>审核人：{{ recorItem.examineBaseInfo.operator }}</div>
        <div>{{ recorItem.examineBaseInfo.examineTime }}</div>
        <div class="pass-text" v-if="recorItem.step == 5">
          <span v-if="recorItem.examineBaseInfo.remark">
            不通过原因：{{ recorItem.examineBaseInfo.remark }}
            <span v-if="!textIf" class="text-item" @click="Text">
              {{ textShow == false ? " 展开全部" : "收起" }}
            </span>
          </span>
          <span v-else>未填写拒绝原因</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  name: "notPass",
  props: {
    recorItem: {
      type: Object,
      default: () => {
        return {
          step: 4,
          examineBaseInfo: {}, //企业基础信息
          qualification: [], //资质数据
          companyTypeInfo: [], //企业类型数据
          scopeInfo: [], //经营范围数据
        };
      },
    },
  },
  data() {
    return {
      textIf: true, // 展开收起隐藏
      textShow: false, // 展开收起切换
      textMax: 20, // 显示最多字数
    };
  },
  computed: {
    ...mapState(["reviewInfoData"]),
  },
  methods: {
    Text() {
      this.textShow = !this.textShow;
      this.textShow == false ? (this.textMax = 20) : (this.textMax = +Infinity);
    },
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  // padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    width: 100%;
    height: 60vh;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
    .passImg {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      margin-left: 35px;
    }
    .pass-text {
      line-height: 20px;
      // padding-top: 90px;
      width: 80%;
      min-width: 500px;
      // height: 100%;
      // min-height: 100px;
      text-align: left;
      display: flex;
      align-items: flex-end;
      font-size: 13px;
      .text-item {
        color: #00bfbf;
        cursor: pointer;
        font-weight: 700;
      }
    }
    .verifier-per {
      height: 94px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 40px;
      margin-left: 35px;
      justify-content: flex-end;
      font-weight: normal;
      div {
        width: 100%;
      }
    }
  }
}
</style>
