<template>
  <div>
    <div class="titlxtd">经营资质图片</div>
    <!-- <div class="txtons">说明：此次仅显示当前更新的经营资质。</div> -->
    <div class="fx" style="flex-wrap: wrap">
      <div
        v-for="(item, index) in recorItem.qualification"
        :key="index"
        style="margin: 0px 40px 20px 0px"
      >
        <div
          style="font-weight: bold; margin-bottom: 10px; text-align: center"
          :class="item.isChanged==1?'yellos':''"
        >
          {{ item.tmpName }}
        </div>
        <div>
          <div v-for="(v, i) in item.picture" :key="i">
            <el-image
              class="photo"
              :src="v"
              :preview-src-list="item.picture"
              v-if="v != ''"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="titlxtd" style="margin-top: 20px">经营类别设置</div>
    <div class="txtons">
      说明：请核实根据资质类型开启的经营类别及效期是否正确！
    </div>
    <div class="cardsyed">
      <div
        v-for="(item, index) in recorItem.scopeInfo"
        :key="index"
        class="cardse"
      >
        <div class="title exceed" :title="item.businessScopeName">
          {{ item.businessScopeName }}
        </div>
        <div>
          <el-switch
            v-model="item.isSetVal"
            active-color="#06B7AE"
            inactive-color="#e6e8eb"
            :active-value="1"
            :inactive-value="2"
          >
          </el-switch>
        </div>
        <div class="input-box">
          <el-date-picker
            editable
            format="yyyy-MM-dd"
            v-model="item.expirationTime"
            type="date"
            placeholder="选择有效日期"
            :picker-options="pickerOptions"
            @change="
              item.expirationTime == '9999-12-31 00:00:00'
                ? (item.isLongTime = true)
                : (item.isLongTime = false)
            "
            value-format="yyyy-MM-dd"
            :disabled="
              item.isSetVal == 2 || item.expirationTime == '9999-12-31 00:00:00'
            "
          >
          </el-date-picker>
        </div>
        <div class="checks">
          <el-checkbox
            v-model="item.isLongTime"
            :disabled="item.isSetVal == 2"
            @change="
              item.expirationTime == '9999-12-31 00:00:00'
                ? (item.expirationTime = '')
                : (item.expirationTime = '9999-12-31 00:00:00')
            "
          >
            长期
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  name: "QualifiCategory",
  props: {
    recorItem: {
      type: Object,
      default: () => {
        return {
          examineBaseInfo: {}, //企业基础信息
          qualification: [], //资质数据
          companyTypeInfo: [], //企业类型数据
          scopeInfo: [], //经营范围数据
        };
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  created() {},
  methods: {
    ...mapActions([
      "postscopeConfig", //提交经营氛围修改
    ]),
    /** 获取详情数据 */
    getItem() {
      var data = { ...this.recorItem };
      data.qualification.forEach((v) => {
        this.$set(v, "picture", []);
        this.$set(v, "text", []);
        v.items.forEach((v1) => {
          if (v1.itemType == 3) {
            v.picture.push(v1.imgUrl);
          }
          if (v1.itemType == 1) {
            v.text.push({ key: v1.itemName, val: v1.itemText });
          }
        });
      });
      data.scopeInfo.forEach((v, i) => {
        if (v.expirationTime == "9999-12-31 00:00:00") {
          v.isLongTime = true;
        } else {
          v.isLongTime = false;
        }
      });
      Object.assign(this.recorItem, data);
    },
  },
};
</script>
<style lang='scss' scoped>
.txtons {
  color: #ff7d00;
  margin-bottom: 20px;
}
.photo {
  width: 150px;
  height: 150px;
  overflow: hidden;
}
.cardsyed {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .cardse {
    width: 600px;
    display: flex;
    align-items: center;
    margin: 5px 0px;
    .title {
      width: 180px;
    }
  }
  .cardse > div {
    margin: 0px 5px;
  }
}
</style>